import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import {dbCollections} from "@/utils/firebaseCollection"
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
import {getCollectionData} from "@/utils/dynamicGetDataQuery.js"
import { getCollectionDataTypesense, typesenseFetchAll } from '@/utils/Typesense/queries'
export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'unique_id', sortable: true },
    { key: 'date_of_enrolment', sortable: true },
    { key: 'student_name', sortable: true },
    { key: 'college_name',label: 'School/College Name',sortable: false },
    { key: 'education', sortable: false, label: 'Degree' },
    { key: 'branchfiedlstream', sortable: false,label: 'Branch/Field/Stream'},
    // { key: 'amount',label: 'Amount (₹)', sortable: false },
    { key: 'status', sortable: true },
    { key: 'representative', sortable: true },
    { key: 'action', sortable: false },
    // { key: 'email', sortable: false },
    // { key: 'role', sortable: false },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    // { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(null)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const representativeOptions = ref(null)

  const college = ref(null)

  const isSpinner = ref(null)

  //Select Filters
  const intake = ref(null)
  const yearFilter = ref(null)
  const representative = ref(null)
  const status = ref(null)
  const employees = ref([])
  const students = ref([])
  const isFetching = ref(false)
  const previuosDisabled = ref(false)
  const nextDisabled = ref(false)
  const firstDoc = ref(null)
  const lastDoc = ref(null)
  const sortingVal  = ref(null)
  const docCategoryTable  = ref(null)
  const sortContect  = ref(null)
  const timeFnction  = ref(null)
  const fetchMoreDataStop = ref(false)


  const dataMeta = computed(() => {
      const localItemsCount = docCategoryTable.value ? docCategoryTable.value.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalUsers.value,
      }
  })
  const filteredStudentList = computed(() => {
    let finalArr = students.value;
    return finalArr
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  onMounted(()=>{
    getEmployeeDataFromTypesense().then(()=>{
      fetchStudentsFromTypesense(true,()=>refetchData());
      isSpinner.value = false;
    })
    // fetchStudents()
  })
  
  watch([searchQuery, roleFilter, planFilter, college], () => {
    refetchData()
  })
  watch([perPage,intake,yearFilter,representative,status],()=>{
    fetchStudentsFromTypesense(true,()=>refetchData());
  })
  
  var usersList = []
  var employeeList = []
  var repOptions=[{value:null,text:'All'}]
  isSpinner.value=true
  function getEmployeeData() {
      return new Promise((resolve, reject) => {
          try {
            if(employees.value && employees.value.length == 0) {
              let whereQueries = [
                  {
                      field: "role",
                      operation: "==",
                      value: 'employee'
                  },
                  {
                      field: "isDeleted",
                      operation: "==",
                      value: false
                  }
              ]
              getCollectionData(`${dbCollections.USERS}`, whereQueries)
                  .then(querySnapshot => {
                      let employeeData = [];
                      querySnapshot.forEach(doc => {
                          employeeData.push(doc.data());
                          employees.value.push(doc.data());
                          repOptions.push({ value: { name: doc.data().name, eid: doc.data().employeeId, id: doc.data().id }, text: doc.data().name });
                      });
                      representativeOptions.value = repOptions;

                      resolve(employeeData);
                  })
                  .catch((err) => {
                      reject(err);
                  });
            } else {
              resolve(employees.value);
            }
          } catch (error) {
              reject(error);
          }
      });
  }
    function handleSortChange(context) {
      let sortFieldObject = {
          unique_id : "createdAt",
          date_of_enrolment : "createdAt",
          student_name : "name",
          college_name : "",
          education : "", 
          branchfiedlstream : "", 
          status : "status.status", 
          representative : "representative.name",
          action : "", 
      }
      const {sortBy,sortDesc} = context
      sortContect.value = {sortBy:sortFieldObject[sortBy],sortDesc};
      fetchStudentsFromTypesense(false,()=>{refetchData()})
  }
  function handleSearch() {
    clearTimeout(timeFnction.value);
    timeFnction.value = setTimeout(() => {
      fetchStudentsFromTypesense(false,()=>{refetchData()});
    }, 1000);
  }
  async function handlePageChange(newPage) {
      currentPage.value = newPage;
      await fetchStudentsFromTypesense(false,()=>{refetchData()})
  }
  function getEmployeeDataFromTypesense() {
    return new Promise((resolve,reject)=>{
      try {
        let searchParameters = {
            collectionName: `${dbCollections.USERS}`,
            search: {
                'q'            : '*',
                'filter_by' : `isDeleted:!=true && role:='employee'`,
                'per_page': 250
            }
        }
  
        getCollectionDataTypesense(searchParameters).then((result)=>{
            typesenseFetchAll(dbCollections.USERS,result.hits,result.found,searchParameters.search,false,(groupResult)=>{
                let employeeData = [];
                if(groupResult.finalArray.length) {
                  groupResult.finalArray.map(e=>e.document).forEach(doc => {
                      employeeData.push(doc);
                      employees.value.push(doc);
                      repOptions.push({ value: { name: doc.name, eid: doc.employeeId, id: doc.id }, text: doc.name });
                  });
                  representativeOptions.value = repOptions;  
                  resolve(employeeData);
                } else {
                  resolve(employeeData);
                }
            })
        }).catch((error)=>{
          reject(error)
          console.error(error)
        });
      } catch (error) {
        console.error(error);
      }
    })
  }
  function fetchStudentsFromTypesense(resetResult = true,callback){
      try {
          if (resetResult) {
              currentPage.value = 1;
              totalUsers.value = 0;
          }

          let searchParameters = {
              collectionName: `${dbCollections.USERS}`,
              search: {
                  q:"*",
                  page: currentPage.value,
                  per_page: perPage.value,
                  num_typos:0
              }
          }

          let filter = `isDeleted:=false && role:='student'`

          if (intake.value !== null && intake.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `intake:=${intake.value}`
          }
          if (yearFilter.value !== null && yearFilter.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `year:=${yearFilter.value}`
          }
          if (representative.value !== null && representative.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `representative.id:=${representative.value.id ? representative.value.id : ''}`
          }
          if (status.value !== null && status.value !== '') {
              if(filter !== '') {
                  filter += ` && `
              }
              filter += `status.status:=${status.value}`
          }

          if(filter !== '' && filter !== null) {
            searchParameters.search.filter_by = filter;
          }
          if (sortContect.value) {
              searchParameters.search.sort_by = `${(sortContect.value.sortBy && sortContect.value.sortBy !== '') ? `${sortContect.value.sortBy}: ${sortContect.value.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
          }
          if(searchQuery.value !== '' && searchQuery.value !== null) {
            searchParameters.search.q = searchQuery.value;
            searchParameters.search.query_by = 'name,representative.name,uniqueId,status.status';
          }

          getCollectionDataTypesense(searchParameters).then(async(result)=>{
              if(result.hits.length) {
                  totalUsers.value = result.found
                  const arrayData = await Promise.all(result.hits.map(async (x) => {
                    const studentObject = x.document;
                    let latestYear = 0;
                    let latestEduData = {};
                
                    studentObject.educationDetails.forEach(ed => {
                        if (ed.yearOfPassing >= latestYear) {
                            latestYear = ed.yearOfPassing;
                            latestEduData = ed;
                        }
                    });
                
                    const updateName = async (ele) => {
                        try {
                            let employeeIndex = employees.value.findIndex((e) => e.id === ele);
                            if (employeeIndex !== -1) {
                                let name = employees.value[employeeIndex]?.name || '';
                                return name;
                            } else {
                                let name = '';
                                if (ele) {
                                    const employeeData = await db.collection(dbCollections.USERS).doc(ele).get();
                                    name = employeeData.data()?.name || '';
                                    return name;
                                } else {
                                    return name;
                                }
                            }
                        } catch (error) {
                            throw error;
                        }
                    };
                
                    let employeeName = await updateName(studentObject.representative.id)
                
                    return {
                        id: studentObject.id,
                        status: studentObject.status,
                        unique_id: studentObject.uniqueId,
                        representative: employeeName,
                        repreID: studentObject.representative.id,
                        intake: studentObject.intake,
                        year: studentObject.year,
                        date_of_enrolment: `${new Date(studentObject.createdAt * 1000).getDate()} ${months[new Date(studentObject.createdAt * 1000).getMonth()]}, ${new Date(studentObject.createdAt * 1000).getFullYear()}`,
                        education: latestEduData.education || '',
                        branchfiedlstream: latestEduData.branchOrStreamOrFieldName || '-',
                        college_name: latestEduData.schoolOrCollegeName || '',
                        isDeleted: studentObject.isDeleted,
                        student_name: studentObject.name,
                        phone_no: '+91 ' + studentObject.mobileNumber,
                        createdInSeconds: studentObject.createdAt
                    };
                }));
                
                students.value = arrayData;
                callback(arrayData);

              } else {
                students.value = []
                totalUsers.value = 0;
                callback([]);
              }
          })
      } catch (error) {
          console.error(error)
          callback([]);
      }
  }
  function fetchStudents(direction = '',refetchData = false) {
    try {
      if(refetchData == false) {
        isSpinner.value = true
          isFetching.value = true;
          students.value = []
        }
        let whereQueries = [
            {
                field: "role",
                operation: "==",
                value: 'student'
            },
            {
                field: "isDeleted",
                operation: "==",
                value: false
            }
        ]

        let limit = 100
        let orderByValue = {field: "createdAt",value: 1}
        let refDoc;
        let refDoc1;
        let limitToLast = 0;
        if(direction == 'next') {
            orderByValue = {field: "createdAt",value: 1}
            refDoc = {
                doc: lastDoc.value
            }
        } else if(direction == 'previous') {
            orderByValue = {field: "createdAt",value: 1}
            refDoc1 = {
              doc: firstDoc.value
            }
            limitToLast = limit
        }
        if(sortingVal.value && sortingVal.value.key!=null) {
          orderByValue = {field: sortingVal.value.key,value: sortingVal.value.value == true ? 1 : 0}
        }
        Promise.allSettled([getEmployeeData(),  getCollectionData(`${dbCollections.USERS}`,whereQueries, orderByValue,limit,'','',refDoc?.doc || '',refDoc1?.doc || '',limitToLast)])
          .then(([employeeDataResult, querySnapshotResult]) => {
            if(querySnapshotResult.status == 'rejected') {
              console.error(querySnapshotResult);
            }
            const employeeData = employeeDataResult.status === 'fulfilled' ? employeeDataResult.value : [];
            const querySnapshot = querySnapshotResult.status === 'fulfilled' ? querySnapshotResult.value : null;
            if(!querySnapshot || querySnapshot.empty == true) {
              isSpinner.value = false;
              isFetching.value = false;
              if(direction == 'next') {
                nextDisabled.value = true;
                lastDoc.value = '';
              } else if(direction == 'previous') {
                previuosDisabled.value = true;
                firstDoc.value = '';
              }
              return;
            }
            // students.value = [];
            const docs =  querySnapshot.docs?.map((x) => ({...x.data(), id: x.id}));
            if(direction == 'next') {
              lastDoc.value = querySnapshot.docs?.[querySnapshot.docs.length - 1];
            } else if(direction == 'previous') {
              firstDoc.value = querySnapshot.docs?.[0];
            }
            let users = [];
            const updateName = async (ele) => {
                try {
                  let employeeIndex = employeeData.findIndex((e)=>e.id === ele);
                  if(employeeIndex !== -1) {
                    let name = employeeData[employeeIndex]?.name || '';
                    return name;
                  } else {
                    let name = '';
                    if(ele) {
                      const employeeData = await db.collection(dbCollections.USERS).doc(ele).get();
                      name = employeeData.data()?.name || '';
                      return name;
                    } else {
                      return name;
                    }
                  }
                } catch (error) {
                    throw error;
                }
            };
            docs.forEach(async(doc)=>{
                const userData = doc;
                const createdAt = new Date(userData.createdAt.seconds * 1000);
            
                let latestYear = 0;
                let latestEduData = {};
            
                userData.educationDetails.forEach(ed => {
                    if (ed.yearOfPassing >= latestYear) {
                        latestYear = ed.yearOfPassing;
                        latestEduData = ed;
                    }
                });
                let employeeName = await updateName(userData.representative.id)
                const userDetail = {
                    id: userData.id,
                    status: userData.status,
                    unique_id: userData.uniqueId,
                    representative: employeeName,
                    repreID: userData.representative.id,
                    intake: userData.intake,
                    year: userData.year,
                    date_of_enrolment: `${createdAt.getDate()} ${months[createdAt.getMonth()]}, ${createdAt.getFullYear()}`,
                    education: latestEduData.education || '',
                    branchfiedlstream: latestEduData.branchOrStreamOrFieldName || '-',
                    college_name: latestEduData.schoolOrCollegeName || '',
                    isDeleted: userData.isDeleted,
                    student_name: userData.name,
                    phone_no: '+91 ' + userData.mobileNumber,
                    createdInSeconds: userData.createdAt.seconds
                };
            
                users.push(userDetail);
                if(users.length === docs.length) {
                  students.value = [...students.value,...users]
                  lastDoc.value = querySnapshot.docs[querySnapshot.docs.length - 1];
                  firstDoc.value = querySnapshot.docs[0];
                  totalUsers.value = students.value.length
                  isSpinner.value = false;
                  isFetching.value = false;
                  if (nextDisabled.value === false && fetchMoreDataStop.value == false) {
                    fetchStudents('next',true);
                  }
                }
              })
          })
          .catch((error) => {
              isFetching.value = false;
              isSpinner.value = false;
              console.error("Error in fetchStudents", error);
          });
    } catch (error) {
        isFetching.value = false;
        console.error("Error in fetchStudents", error);
    }
  }

  const deleteStudent = (selectedStud,cb)=>{
    var index = (currentPage.value-1) * perPage.value + selectedStud.index + 1

    db
      .collection(dbCollections.USERS)
      .doc(selectedStud.item.id)
      .update({
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        isDeleted:true
      })
      .then(()=>{
        usersList.splice(index-1,1)
        refetchData()
        cb(true,'success')
      })
      .catch(error=>{
        console.log(error)
        cb(false,error.message)
      })
  }

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    representativeOptions,
    // closeInquiry,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    isSpinner,

    deleteStudent,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    intake,
    yearFilter,
    representative,
    status,
    college,
    fetchStudents,
    students,
    docCategoryTable,
    filteredStudentList,
    nextDisabled,
    handlePageChange,
    handleSortChange,
    handleSearch
  }
}
